@media only screen and (max-width: 500px) {
  .default-screen-mobile {
    display: block;
  }

  .default-screen-desktop {
    display: none;
  }

  .content-container {
    position: absolute;
    top: 80px;
    left: 0px;
    right: 0px;
    bottom: 0px;
    padding: 20px;
    background-color: #f2f2f2;
    overflow: auto;
    padding-bottom: 140px;
  }

  .telegram-manage-api-desktop {
    display: none;
  }

  .telegram-manage-api-mobile {
    display: block;
  }
}

@media only screen and (min-width: 500px) {
  .default-screen-mobile {
    display: none;
  }

  .default-screen-desktop {
    display: block;
  }

  .content-container {
    position: absolute;
    top: 80px;
    left: 200px;
    right: 0px;
    bottom: 0px;
    padding: 20px;
    background-color: #f2f2f2;
    overflow: auto;
    padding-bottom: 140px;
  }

  .telegram-manage-api-desktop {
    display: block;
  }

  .telegram-manage-api-mobile {
    display: none;
  }
}

.page-header {
  color: #000;
  padding-bottom: 2px;
  border-bottom: 1px solid #006600;
  margin-bottom: 10px;
}
.page-header span.dash {
  color: #a6a6a6;
  font-size: 14px;
}
.page-header span.parent-title {
  color: #a6a6a6;
  font-size: 14px;
}

.box-container {
  /* padding: 10px;
  background-color:#fff;
  border: 5px solid #d9d9d9;
  border-radius: 10px; */

  padding: 10px;
  background-color: #fff;
  border-radius: 5px;
  box-shadow: rgb(0 0 0 / 24%) 0px 3px 8px;
}
.form-button-container {
  text-align: right;
  padding: 5px 20px;
  border-top: 1px solid #d9d9d9;
}

.date-picker {
  padding: 6px 12px;
  border: 1px solid #ced4da;
  border-radius: 0.25rem;
  color: #495057;
}

.label-column {
  width: 250px;
}

.form-check-input {
  margin-top: 0px !important;
  margin-left: 0px !important;
}

.promo {
  display: inline-block;
  background-color: #00ace6;
  color: #fff;
  border-radius: 8px;
  padding: 1px 4px;
  font-size: 12px;
  margin-right: 3px;
  font-family: monospace;
}
