.App {
  text-align: center;
}

.App-logo {
  animation: App-logo-spin infinite 20s linear;
  height: 80px;
}

.App-header {
  background-color: #222;
  height: 150px;
  padding: 20px;
  color: white;
}

.App-title {
  font-size: 1.5em;
}

.App-intro {
  font-size: large;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.datefilter {
  width: 100%;
  height: auto;
  display: inherit;
  padding: 6px 12px;
  border: 1px solid #ced4da;
  border-radius: 0.25rem;
  color: #495057;
}
.enddatefilter {
  margin-left: 60px;
  height: auto;
  display: inherit;
  padding: 6px 12px;
  border: 1px solid #ced4da;
  border-radius: 0.25rem;
  color: #495057;
}
.contentDate {
  float: right;
  display: inline-flex;
}

.contentDateLeft {
  float: left;
  display: inline-flex;
}

.list-form {
  overflow-y: auto;
  margin: 0 auto;
  position: relative;
  background-color: white;
  max-height: 50vh;
}

.content-item-list {
  margin-top: 20px;
  background-color: white;
  height: 50vh;
  box-shadow: rgba(50, 50, 93, 0.25) 0px 6px 12px -2px,
    rgba(0, 0, 0, 0.3) 0px 3px 7px -3px;
}

.ReactTable {
  height: 1000px;
  overflow-y: scroll;
  overflow-x: hidden;
}

.rt-thead {
  position: sticky;
  top: 0;
  z-index: 2;
  background: whitesmoke;
  color: black;
}

.rt-tbody {
  overflow-y: scroll;
  overflow-x: hidden;
  height: 500px;
  color: black;
  background-color: whitesmoke;
  scrollbar-width: none;
  -ms-overflow-style: none;
}

.rt-tbody::-webkit-scrollbar {
  display: none;
}

.sticky {
  position: sticky !important;
  left: 0;
  top: 0;
  z-index: 1;
  background-color: #e3e3e3;
}

.-striped .rt-tr.-odd {
  background-color: white;
}

.react-table {
  tr.-striped:nth-child(odd) {
    background-color: white;
  }
}
