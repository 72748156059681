.menu-container {
  position: absolute;
  top: 80px;
  left: 0px;
  bottom: 0px;
  width: 200px;
  background-color: #1f324b;
  box-shadow: 0px 1px 4px 1px rgba(0, 0, 0, 0.3);
  z-index: 0;
  padding-top: 10px;
  overflow-y: scroll;
  overflow-x: hidden;
}

.menu-container::-webkit-scrollbar {
  display: none;
}

.menu {
  padding: 8px 10px 8px 15px;
  color: #fff;
  /* border-right: 6px solid #333; */
  font-size: 14px;
}
.menu:hover {
  background-color: #59c9b6;
  border-right: 6px solid #cccccc;
}
.menu-parent:hover {
  cursor: pointer;
}

.menu .chevron-container {
  display: inline;
  float: right;
  color: rgb(255, 255, 255);
}

.link-custom:hover {
  text-decoration: none;
}

.submenu-container {
  /*border-top:1px solid #404040;
    border-bottom:1px solid #404040;*/
  background-color: #07213c;
}
.submenu {
  padding: 8px 0px 8px 30px;
  color: #fff;
  border-right: 6px solid #07213c;
  font-size: 14px;
  text-decoration: none;
  /* margin-left: 10px; */
}
.submenu:hover {
  background-color: #59c9b6;
  border-right: 6px solid #cccccc;
  /* margin-left: 10px; */
}

.menu-active {
  background-color: #59c9b6 !important;
  border-right: 6px solid #cccccc;
}
