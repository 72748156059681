body {
  margin: 0;
  padding: 0;
  font-family: arial, sans-serif;
  font-size: 16px;
}

table {
  width: 100%;
}

.center-loader {
  text-align: center;
  margin-top: 10;
}

.left-result {
  text-align: left;
  margin-top: 10;
}

.App {
  text-align: center;
}

.App-logo {
  -webkit-animation: App-logo-spin infinite 20s linear;
          animation: App-logo-spin infinite 20s linear;
  height: 80px;
}

.App-header {
  background-color: #222;
  height: 150px;
  padding: 20px;
  color: white;
}

.App-title {
  font-size: 1.5em;
}

.App-intro {
  font-size: large;
}

@-webkit-keyframes App-logo-spin {
  from {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}

@keyframes App-logo-spin {
  from {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}

.datefilter {
  width: 100%;
  height: auto;
  display: inherit;
  padding: 6px 12px;
  border: 1px solid #ced4da;
  border-radius: 0.25rem;
  color: #495057;
}
.enddatefilter {
  margin-left: 60px;
  height: auto;
  display: inherit;
  padding: 6px 12px;
  border: 1px solid #ced4da;
  border-radius: 0.25rem;
  color: #495057;
}
.contentDate {
  float: right;
  display: inline-flex;
}

.contentDateLeft {
  float: left;
  display: inline-flex;
}

.list-form {
  overflow-y: auto;
  margin: 0 auto;
  position: relative;
  background-color: white;
  max-height: 50vh;
}

.content-item-list {
  margin-top: 20px;
  background-color: white;
  height: 50vh;
  box-shadow: rgba(50, 50, 93, 0.25) 0px 6px 12px -2px,
    rgba(0, 0, 0, 0.3) 0px 3px 7px -3px;
}

.ReactTable {
  height: 1000px;
  overflow-y: scroll;
  overflow-x: hidden;
}

.rt-thead {
  position: -webkit-sticky;
  position: sticky;
  top: 0;
  z-index: 2;
  background: whitesmoke;
  color: black;
}

.rt-tbody {
  overflow-y: scroll;
  overflow-x: hidden;
  height: 500px;
  color: black;
  background-color: whitesmoke;
  scrollbar-width: none;
  -ms-overflow-style: none;
}

.rt-tbody::-webkit-scrollbar {
  display: none;
}

.sticky {
  position: -webkit-sticky !important;
  position: sticky !important;
  left: 0;
  top: 0;
  z-index: 1;
  background-color: #e3e3e3;
}

.-striped .rt-tr.-odd {
  background-color: white;
}

.react-table {
  tr.-striped:nth-child(odd) {
    background-color: white;
  }
}

.login-container {
  width: 100%;
  height: 100vh;
  background: linear-gradient(rgba(0, 0, 0, 0.3), rgba(0, 0, 0, 0.3)),
    url(/static/media/bg_login.23e43926.jpg);
  background-size: cover;
  background-blend-mode: overlay;
}

.login-box-container {
  position: absolute;
  top: 50%;
  left: 50%;
  width: 350px;
  background-color: #fff;
  border-radius: 8px;
  box-shadow: 0px 0px 4px 1px rgba(0, 0, 0, 0.5);
  -webkit-transform: translate(-50%, -50%);
          transform: translate(-50%, -50%);

  padding: 20px;
}

.logo {
  text-align: center;
  padding: 10px;
}
.logo img {
  width: 300px;
  margin-bottom: 10%;
}

.login-title {
  font-size: 18px;
  color: #000;
  text-align: center;
  margin-bottom: 10px;
}
.login-input {
  margin-bottom: 8px;
}
.login-input input {
  padding: 5px 15px;
  background-color: #fff;
  border-radius: 5px;
  border: 1px solid #808080;
  width: 100%;
  box-sizing: border-box;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
}

.login-error {
  text-align: center;
  color: #cc0000;
  font-size: 13px;
}

.button-container {
  margin-top: 20px;
}

.login-btn-login {
  background-color: #29a329;
  border: 0px;
  width: 100%;
  color: #fff;
  border-radius: 8px;
  padding: 8px;
}

@media only screen and (max-width: 500px) {
  .default-screen-mobile {
    display: block;
  }

  .default-screen-desktop {
    display: none;
  }

  .content-container {
    position: absolute;
    top: 80px;
    left: 0px;
    right: 0px;
    bottom: 0px;
    padding: 20px;
    background-color: #f2f2f2;
    overflow: auto;
    padding-bottom: 140px;
  }

  .telegram-manage-api-desktop {
    display: none;
  }

  .telegram-manage-api-mobile {
    display: block;
  }
}

@media only screen and (min-width: 500px) {
  .default-screen-mobile {
    display: none;
  }

  .default-screen-desktop {
    display: block;
  }

  .content-container {
    position: absolute;
    top: 80px;
    left: 200px;
    right: 0px;
    bottom: 0px;
    padding: 20px;
    background-color: #f2f2f2;
    overflow: auto;
    padding-bottom: 140px;
  }

  .telegram-manage-api-desktop {
    display: block;
  }

  .telegram-manage-api-mobile {
    display: none;
  }
}

.page-header {
  color: #000;
  padding-bottom: 2px;
  border-bottom: 1px solid #006600;
  margin-bottom: 10px;
}
.page-header span.dash {
  color: #a6a6a6;
  font-size: 14px;
}
.page-header span.parent-title {
  color: #a6a6a6;
  font-size: 14px;
}

.box-container {
  /* padding: 10px;
  background-color:#fff;
  border: 5px solid #d9d9d9;
  border-radius: 10px; */

  padding: 10px;
  background-color: #fff;
  border-radius: 5px;
  box-shadow: rgb(0 0 0 / 24%) 0px 3px 8px;
}
.form-button-container {
  text-align: right;
  padding: 5px 20px;
  border-top: 1px solid #d9d9d9;
}

.date-picker {
  padding: 6px 12px;
  border: 1px solid #ced4da;
  border-radius: 0.25rem;
  color: #495057;
}

.label-column {
  width: 250px;
}

.form-check-input {
  margin-top: 0px !important;
  margin-left: 0px !important;
}

.promo {
  display: inline-block;
  background-color: #00ace6;
  color: #fff;
  border-radius: 8px;
  padding: 1px 4px;
  font-size: 12px;
  margin-right: 3px;
  font-family: monospace;
}

@media only screen and (max-width: 500px) {
    .header-screen-mobile {
        display: block;
    }

    .header-screen-desktop {
        display: none;
    }
}

@media only screen and (min-width: 500px) {
    .header-screen-mobile {
        display: none;
    }

    .header-screen-desktop {
        display: block;
    }
}

.header-container{
    height:80px;
    background-color:#fff;
    border-bottom:1px solid #d9d9d9;
    box-shadow: 0px 0px 4px 1px rgba(0,0,0,.1);
    z-index: 200;
}

.header-title{
    font-size: 18px;
    color:#333;
    line-height:80px;
    vertical-align: middle;
    margin-left:20px;
    float:left;
}

.header-profilename-mobile {
    vertical-align: middle;
    display: inline;
    float: right;
    font-size: 10px;
}

.header-profilename{
    line-height: 80px;
    vertical-align: middle;
    margin-right:20px;
    display: inline;
    float:right;
    font-size: 14px;
}

.header-profilepic{
    margin-top: 10px;
    margin-right:20px;
    display: inline;
    float:right;
}

.header-profilepic-mobile {
    display: flex !important;
    align-content: center;
    justify-content: center;
    align-items: center;
    margin-right: 20px;
    display: inline;
    float: right;
}

.header-profileimg{
    width:40px;
    height:40px;
    border-radius:80%;
    border:1px solid #d9d9d9;
    overflow: hidden;
    text-align: center;
    margin-top:5px;
}

.header-profileimg img{
    width:40px;
    height:40px;
    line-height: 40px;
}

.header-profileimg:hover{
    border:1px solid #6699ff;
}

.header-addon{
    margin-left:20px;
    float:left;
    position: relative;
}

.header-addon .select-community{
    border:1px solid #d9d9d9;
    border-radius:10px;
    color:#333;
    font-size: 14px;
    padding:4px 10px;
    min-width:140px;
    margin-top:9px;
}

.header-addon .select-community:hover{
    cursor: pointer;
}

.select-community-icon{
    float:right;
    margin-left:6px;
}

.select-community-option-container{
    position: absolute;
    top:42px;
    z-index: 160;
    border:1px solid #d9d9d9;
    background-color:#fff;
    max-height:300px;
    overflow: auto;
    border-bottom:0px;
    width:200px;
}

.select-community-option-container .select-community-option{
    padding:4px 10px;
    border-bottom:1px solid #d9d9d9;
    color:#333;
    font-size: 13px;
}

.select-community-option-container .select-community-option:hover{
    color:#000;
    background-color:#e6e6e6;
    cursor: pointer;
}

.menu-container {
  position: absolute;
  top: 80px;
  left: 0px;
  bottom: 0px;
  width: 200px;
  background-color: #1f324b;
  box-shadow: 0px 1px 4px 1px rgba(0, 0, 0, 0.3);
  z-index: 0;
  padding-top: 10px;
  overflow-y: scroll;
  overflow-x: hidden;
}

.menu-container::-webkit-scrollbar {
  display: none;
}

.menu {
  padding: 8px 10px 8px 15px;
  color: #fff;
  /* border-right: 6px solid #333; */
  font-size: 14px;
}
.menu:hover {
  background-color: #59c9b6;
  border-right: 6px solid #cccccc;
}
.menu-parent:hover {
  cursor: pointer;
}

.menu .chevron-container {
  display: inline;
  float: right;
  color: rgb(255, 255, 255);
}

.link-custom:hover {
  text-decoration: none;
}

.submenu-container {
  /*border-top:1px solid #404040;
    border-bottom:1px solid #404040;*/
  background-color: #07213c;
}
.submenu {
  padding: 8px 0px 8px 30px;
  color: #fff;
  border-right: 6px solid #07213c;
  font-size: 14px;
  text-decoration: none;
  /* margin-left: 10px; */
}
.submenu:hover {
  background-color: #59c9b6;
  border-right: 6px solid #cccccc;
  /* margin-left: 10px; */
}

.menu-active {
  background-color: #59c9b6 !important;
  border-right: 6px solid #cccccc;
}

.menuprofile-container{
    position: absolute;
    top:52px;
    right:3px;
    width:200px;
    background-color:#fff;
    border:1px solid #d9d9d9;
    z-index: 120;
}

@media only screen and (max-width: 500px) {
    .menuprofile-container {
        position: absolute;
        top: 62px;
        right: 3px;
        width: 200px;
        background-color: #fff;
        border: 1px solid #d9d9d9;
        z-index: 2500 !important;
    }
}

.menuprofile-link{
    padding: 5px 10px;
    font-size: 13px;
    text-decoration: none;
    width:100%;
}
.menuprofile-link:hover{
    background-color:#e6e6e6;
    cursor: pointer;
}
.none-decoration{
    color:#000;
    width: 100%;
    display: block;
}
.none-decoration:hover{
    color:#000;
    text-decoration: none;
}
.logout{
    border-top:1px solid #d9d9d9;
}
.loading-container{
    position: absolute;
    top:0px;
    bottom:0px;
    left:0px;
    right:0px;
    background-color:rgba(0,0,0,.7);
    z-index: 1000;
}

.loading-container .gif-container{
    position: absolute;
    top:50%;
    left:50%;
    -webkit-transform: translate(-50%, -50%);
            transform: translate(-50%, -50%);
}

@media only screen and (max-width: 500px) {
  .dashboard-screen-mobile {
    display: block;
  }

  .dashboard-screen-desktop {
    display: none;
  }
}

@media only screen and (min-width: 500px) {
  .dashboard-screen-mobile {
    display: none;
  }

  .dashboard-screen-desktop {
    display: block;
  }
}

.communityname {
  color: #004d99;
}

/* Dashboard */
.dashboard-vector-map {
  width: 100%;
  height: 300px;
}

.proBanner {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 9999;
}

.row {
  display: flex;
  flex-wrap: wrap;
  margin-right: -0.75 rem;
  margin-left: -0.75 rem;
}

.grid-margin {
  margin-bottom: 1.5 rem;
}

.card.corona-gradient-card {
  background-image: linear-gradient(to left, #d41459, #911a6c);
  border-radius: 6 px;
  padding: 10px;
}

.purchase-popup {
  background: #000;
  color: #fff;
  padding: 15px 20px;
  border-radius: 3px;
}

.card-background-white {
  background-color: #0e5394 !important;
}

.card .card-title {
  color: #000 !important;
  margin-bottom: 1.125 rem;
  text-transform: capitalize;
}

.btn {
  margin-right: 20px;
  font-weight: 500;
  color: white;
  border-radius: 5px;
  transition-duration: 0.2s;
}

.col-9 {
  flex: 0 0 75%;
  max-width: 75%;
}

.col-3 {
  flex: 0 0 25%;
}

.align-self-start {
  align-self: flex-start !important;
}

.align-items-center {
  align-items: center !important;
}

.d-flex {
  display: flex !important;
}

.mb-0,
.my-0 {
  margin-bottom: 0 !important;
}

.icon.icon-box-success {
  width: 40 px;
  height: 37 px;
  background: rgba(0, 210, 91, 0.11);
  border-radius: 7 px;
  color: #00d25b;
}

.card {
  border-radius: 0.25 rem;
  position: relative;
  display: flex;
  flex-direction: column;
  min-width: 0;
  word-wrap: break-word;
  background-color: #fff !important;
  background-clip: border-box;
  border: 1 px solid rgba(0, 0, 0, 0.125);
  border-radius: 0.25 rem;
}

.card-body {
  padding: 1.75 rem 1.5625 rem;
  color: black !important;
}

.stretch-card {
  display: block;
  align-items: stretch;
  justify-content: stretch;
  width: 100%;
}

.text-white {
  color: white !important;
}

p {
  margin-bottom: auto;
  margin-top: auto;
  color: darken(#e4e4e4, 20%);
  font-weight: 400;
  vertical-align: middle;
  line-height: 1;
}

h4 {
  font-size: 1.125rem;
  font-weight: 500;
  color: #fff;
}

h3 {
  font-size: 1.125rem;
  font-weight: 500;
  color: black !important;
}

.card.card-body {
  color: black !important;
}

i {
  vertical-align: middle;
  line-height: 1;
  margin: auto 0;
  color: darken(#e4e4e4, 20%);
}

.bannerClose {
  cursor: pointer;
}

.dashboard-paper {
  box-shadow: rgb(0 0 0 / 35%) 0px 5px 15px;
  background-color: #5ed5c4;
  padding: 10px;
  border-radius: 10px;
  margin-bottom: 20px;
}

.dashboard-panel {
  box-shadow: rgb(60 64 67 / 30%) 0px 1px 2px 0px,
    rgb(60 64 67 / 15%) 0px 1px 3px 1px;
  background-color: #fff;
  /* padding: 10px; */
  border-radius: 10px;
  /* margin-bottom: 20px; */
}

.dashboard-total {
  box-shadow: rgb(60 64 67 / 30%) 0px 1px 2px 0px,
    rgb(60 64 67 / 15%) 0px 1px 3px 1px;
  background-color: #fff;
  border-radius: 6px;
}

.outerDiv {
  /* width: 1000px; */
  display: flex;
  flex-direction: row;
  overflow: auto;
}

.innerDiv {
  width: 200px;
  height: 200px;
  background-color: grey;
  margin: 10px;
}

.container-total {
  display: flex;
  flex-wrap: no-wrap;
  overflow-x: auto;
  margin: 20px;
}

.hs > li,
.item {
  scroll-snap-align: center;
  padding: calc(var(--gutter) / 2 * 1.5);
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background: #fff;
  border-radius: 8px;
}

.no-scrollbar {
  scrollbar-width: none;
  margin-bottom: 0;
  padding-bottom: 0;
}

.no-scrollbar::-webkit-scrollbar {
  display: none;
}

.icons {
  background-color: #fff;
  margin-top: 5px;
  margin-bottom: 0px;
  padding-top: 10px;
  padding-bottom: 10px;
}

.home-icons-container::after {
  content: "";
  display: table;
  clear: both;
}

.home-icons-container .icon-column {
  width: 12%;
  display: inline-block;
  text-align: center;
  padding-top: 5px;
  padding-bottom: 5px;
}

.link-label {
  font-size: 11px;
  color: #1d2043;
  text-decoration: none;
  cursor: pointer;
  position: relative;
  font-weight: 600;
  display: inline-table;
  flex-direction: column;
}

.link-label img {
  width: 60px;
  margin-bottom: 5px;
}

.tabs-container {
  width: 100%;
  margin: 0px;
}

.tabs-header {
  display: flex;
  justify-content: flex-start;
  margin-bottom: 10px;
  border-bottom: 2px solid #ccc;
}

.tab-button {
  padding: 10px 20px;
  cursor: pointer;
  background-color: transparent;
  border: none;
  outline: none;
  font-size: 16px;
  color: black;
  transition: background-color 0.3s ease;
  border-bottom: 2px solid transparent;
  background-color: white;
}

.tab-button:hover {
  /* background-color: #1f324b; */
  color: "#59c9b6";
  /* font-weight: bolder; */
}

.tab-button.active {
  color: #fff;
  background-color: #1f324b;
  border-bottom: 3px solid #5ed5c4;
  border-left: 3px solid #5ed5c4;
  /* font-weight: 800; */
}

.select-multi-column-container{
    position: relative;
    display: inline-block;
}

.select-multi-column-element{
    min-width:150px;
    border-radius: 5px;
    border:1px solid #d9d9d9;
    background-color:#fff;
    color:#333;
    padding:4px 10px;
}

.select-multi-column-value{
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    display: inline-block;
    max-width: 91%;
}

.select-multi-column-icon{
    float:right;
    margin-left:6px;
}
.select-multi-column-options-container{
    position: absolute;
    border:1px solid #d9d9d9;
    background-color:#fff;
    box-shadow: 1px 1px 4px 1px rgba(0,0,0,.1);
    max-height: 200px;
    overflow: auto;
    top:36px;
    z-index: 200;
}
.select-multi-column-options-container table{
    width:100%;
    border-collapse: collapse;
}
.select-multi-column-options:hover{
    background-color:#f2f2f2;
    color:#000;
    cursor: pointer;
}
.select-multi-column-options td{
    padding:4px 10px;
    color:#333;
    font-size: 13px;
    border-bottom:1px solid #d9d9d9;
}


.pictureuploader-container{
    padding:5px;
}

.pictureuploader-info-container{
    margin-top:5px;
}
.warning-info{
    float:left;
    color:#cc0000;
    font-size: 12px;
}
.max-file-info{
    float:right;
    color:#999999;
    font-size: 12px;
}

.pictureuploader-droparea{
    border-radius:5px;
    border:3px dashed #d9d9d9;
    background-color:#f2f2f2;
    min-height:200px;
    position: relative;
    overflow: hidden;
}

.pictureuploader-button-container{
    padding:5px 15px 0px 15px;
}

.drag-caption{
    position: absolute;
    text-align: center;
    top:50%;
    left:50%;
    -webkit-transform: translate(-50%, -50%);
            transform: translate(-50%, -50%);
    color:#b3b3b3;
}

.pic-col{
    padding:10px;
    position: relative;
}
.pic-col img{
    width:100%;
}

.pic-col .delete{
    color:#f2f2f2;
    position: absolute;
    top:10px;
    right:20px;
}
.pic-col .delete:hover{
    color:#cc0000;
    cursor: pointer;
}

.pictureuploader-onupload{
    position: absolute;
    top:0px;
    bottom:0px;
    left:0px;
    right:0px;
    z-index: 100;
    /*background: rgba(0,0,0,.4);*/
}
.pictureuploader-onupload::after{
    content: "";
    position: absolute;
    top:0px;
    bottom:0px;
    left:0px;
    right:0px;
    z-index: 90;
    background:url(/static/media/bg-pattern.5ac418b2.jpg);
    opacity: 0.5;
}

.pictureuploader-onupload .loading-container{
    width:180px;
    position: absolute;
    top:50%;
    left:50%;
    -webkit-transform: translate(-50%, -50%);
            transform: translate(-50%, -50%);
    z-index: 110;
    
}
.pictureuploader-onupload .loading-container .bar-container{
    width:100%;
    height:20px;
    background-color:#f2f2f2;
    border-radius:3px;
    border:1px solid #b3b3b3;
    overflow: hidden;
    box-shadow: 0px 0px 4px 1px rgba(0,0,0,.3) inset;
    
}
.pictureuploader-onupload .loading-container .bar-container .bar{
    height:19px;
    background-color:#0080ff;
    box-shadow: 0px 0px 4px 1px rgba(0,0,0,.3) inset;
    border-top-right-radius: 3px;
    border-bottom-right-radius: 3px;
}

.pictureuploader-onupload .loading-container .upload-text{
    text-align: center;
    color:#fff;
    font-size: 12px;
}
