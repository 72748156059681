@media only screen and (max-width: 500px) {
  .dashboard-screen-mobile {
    display: block;
  }

  .dashboard-screen-desktop {
    display: none;
  }
}

@media only screen and (min-width: 500px) {
  .dashboard-screen-mobile {
    display: none;
  }

  .dashboard-screen-desktop {
    display: block;
  }
}

.communityname {
  color: #004d99;
}

/* Dashboard */
.dashboard-vector-map {
  width: 100%;
  height: 300px;
}

.proBanner {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 9999;
}

.row {
  display: flex;
  flex-wrap: wrap;
  margin-right: -0.75 rem;
  margin-left: -0.75 rem;
}

.grid-margin {
  margin-bottom: 1.5 rem;
}

.card.corona-gradient-card {
  background-image: linear-gradient(to left, #d41459, #911a6c);
  border-radius: 6 px;
  padding: 10px;
}

.purchase-popup {
  background: #000;
  color: #fff;
  padding: 15px 20px;
  border-radius: 3px;
}

.card-background-white {
  background-color: #0e5394 !important;
}

.card .card-title {
  color: #000 !important;
  margin-bottom: 1.125 rem;
  text-transform: capitalize;
}

.btn {
  margin-right: 20px;
  font-weight: 500;
  color: white;
  border-radius: 5px;
  transition-duration: 0.2s;
}

.col-9 {
  flex: 0 0 75%;
  max-width: 75%;
}

.col-3 {
  flex: 0 0 25%;
}

.align-self-start {
  align-self: flex-start !important;
}

.align-items-center {
  align-items: center !important;
}

.d-flex {
  display: flex !important;
}

.mb-0,
.my-0 {
  margin-bottom: 0 !important;
}

.icon.icon-box-success {
  width: 40 px;
  height: 37 px;
  background: rgba(0, 210, 91, 0.11);
  border-radius: 7 px;
  color: #00d25b;
}

.card {
  border-radius: 0.25 rem;
  position: relative;
  display: flex;
  flex-direction: column;
  min-width: 0;
  word-wrap: break-word;
  background-color: #fff !important;
  background-clip: border-box;
  border: 1 px solid rgba(0, 0, 0, 0.125);
  border-radius: 0.25 rem;
}

.card-body {
  padding: 1.75 rem 1.5625 rem;
  color: black !important;
}

.stretch-card {
  display: block;
  align-items: stretch;
  justify-content: stretch;
  width: 100%;
}

.text-white {
  color: white !important;
}

p {
  margin-bottom: auto;
  margin-top: auto;
  color: darken(#e4e4e4, 20%);
  font-weight: 400;
  vertical-align: middle;
  line-height: 1;
}

h4 {
  font-size: 1.125rem;
  font-weight: 500;
  color: #fff;
}

h3 {
  font-size: 1.125rem;
  font-weight: 500;
  color: black !important;
}

.card.card-body {
  color: black !important;
}

i {
  vertical-align: middle;
  line-height: 1;
  margin: auto 0;
  color: darken(#e4e4e4, 20%);
}

.bannerClose {
  cursor: pointer;
}

.dashboard-paper {
  box-shadow: rgb(0 0 0 / 35%) 0px 5px 15px;
  background-color: #5ed5c4;
  padding: 10px;
  border-radius: 10px;
  margin-bottom: 20px;
}

.dashboard-panel {
  box-shadow: rgb(60 64 67 / 30%) 0px 1px 2px 0px,
    rgb(60 64 67 / 15%) 0px 1px 3px 1px;
  background-color: #fff;
  /* padding: 10px; */
  border-radius: 10px;
  /* margin-bottom: 20px; */
}

.dashboard-total {
  box-shadow: rgb(60 64 67 / 30%) 0px 1px 2px 0px,
    rgb(60 64 67 / 15%) 0px 1px 3px 1px;
  background-color: #fff;
  border-radius: 6px;
}

.outerDiv {
  /* width: 1000px; */
  display: flex;
  flex-direction: row;
  overflow: auto;
}

.innerDiv {
  width: 200px;
  height: 200px;
  background-color: grey;
  margin: 10px;
}

.container-total {
  display: flex;
  flex-wrap: no-wrap;
  overflow-x: auto;
  margin: 20px;
}

.hs > li,
.item {
  scroll-snap-align: center;
  padding: calc(var(--gutter) / 2 * 1.5);
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background: #fff;
  border-radius: 8px;
}

.no-scrollbar {
  scrollbar-width: none;
  margin-bottom: 0;
  padding-bottom: 0;
}

.no-scrollbar::-webkit-scrollbar {
  display: none;
}

.icons {
  background-color: #fff;
  margin-top: 5px;
  margin-bottom: 0px;
  padding-top: 10px;
  padding-bottom: 10px;
}

.home-icons-container::after {
  content: "";
  display: table;
  clear: both;
}

.home-icons-container .icon-column {
  width: 12%;
  display: inline-block;
  text-align: center;
  padding-top: 5px;
  padding-bottom: 5px;
}

.link-label {
  font-size: 11px;
  color: #1d2043;
  text-decoration: none;
  cursor: pointer;
  position: relative;
  font-weight: 600;
  display: inline-table;
  flex-direction: column;
}

.link-label img {
  width: 60px;
  margin-bottom: 5px;
}

.tabs-container {
  width: 100%;
  margin: 0px;
}

.tabs-header {
  display: flex;
  justify-content: flex-start;
  margin-bottom: 10px;
  border-bottom: 2px solid #ccc;
}

.tab-button {
  padding: 10px 20px;
  cursor: pointer;
  background-color: transparent;
  border: none;
  outline: none;
  font-size: 16px;
  color: black;
  transition: background-color 0.3s ease;
  border-bottom: 2px solid transparent;
  background-color: white;
}

.tab-button:hover {
  /* background-color: #1f324b; */
  color: "#59c9b6";
  /* font-weight: bolder; */
}

.tab-button.active {
  color: #fff;
  background-color: #1f324b;
  border-bottom: 3px solid #5ed5c4;
  border-left: 3px solid #5ed5c4;
  /* font-weight: 800; */
}
