@media only screen and (max-width: 500px) {
    .header-screen-mobile {
        display: block;
    }

    .header-screen-desktop {
        display: none;
    }
}

@media only screen and (min-width: 500px) {
    .header-screen-mobile {
        display: none;
    }

    .header-screen-desktop {
        display: block;
    }
}

.header-container{
    height:80px;
    background-color:#fff;
    border-bottom:1px solid #d9d9d9;
    box-shadow: 0px 0px 4px 1px rgba(0,0,0,.1);
    z-index: 200;
}

.header-title{
    font-size: 18px;
    color:#333;
    line-height:80px;
    vertical-align: middle;
    margin-left:20px;
    float:left;
}

.header-profilename-mobile {
    vertical-align: middle;
    display: inline;
    float: right;
    font-size: 10px;
}

.header-profilename{
    line-height: 80px;
    vertical-align: middle;
    margin-right:20px;
    display: inline;
    float:right;
    font-size: 14px;
}

.header-profilepic{
    margin-top: 10px;
    margin-right:20px;
    display: inline;
    float:right;
}

.header-profilepic-mobile {
    display: flex !important;
    align-content: center;
    justify-content: center;
    align-items: center;
    margin-right: 20px;
    display: inline;
    float: right;
}

.header-profileimg{
    width:40px;
    height:40px;
    border-radius:80%;
    border:1px solid #d9d9d9;
    overflow: hidden;
    text-align: center;
    margin-top:5px;
}

.header-profileimg img{
    width:40px;
    height:40px;
    line-height: 40px;
}

.header-profileimg:hover{
    border:1px solid #6699ff;
}

.header-addon{
    margin-left:20px;
    float:left;
    position: relative;
}

.header-addon .select-community{
    border:1px solid #d9d9d9;
    border-radius:10px;
    color:#333;
    font-size: 14px;
    padding:4px 10px;
    min-width:140px;
    margin-top:9px;
}

.header-addon .select-community:hover{
    cursor: pointer;
}

.select-community-icon{
    float:right;
    margin-left:6px;
}

.select-community-option-container{
    position: absolute;
    top:42px;
    z-index: 160;
    border:1px solid #d9d9d9;
    background-color:#fff;
    max-height:300px;
    overflow: auto;
    border-bottom:0px;
    width:200px;
}

.select-community-option-container .select-community-option{
    padding:4px 10px;
    border-bottom:1px solid #d9d9d9;
    color:#333;
    font-size: 13px;
}

.select-community-option-container .select-community-option:hover{
    color:#000;
    background-color:#e6e6e6;
    cursor: pointer;
}
