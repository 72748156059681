.select-multi-column-container{
    position: relative;
    display: inline-block;
}

.select-multi-column-element{
    min-width:150px;
    border-radius: 5px;
    border:1px solid #d9d9d9;
    background-color:#fff;
    color:#333;
    padding:4px 10px;
}

.select-multi-column-value{
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    display: inline-block;
    max-width: 91%;
}

.select-multi-column-icon{
    float:right;
    margin-left:6px;
}
.select-multi-column-options-container{
    position: absolute;
    border:1px solid #d9d9d9;
    background-color:#fff;
    box-shadow: 1px 1px 4px 1px rgba(0,0,0,.1);
    max-height: 200px;
    overflow: auto;
    top:36px;
    z-index: 200;
}
.select-multi-column-options-container table{
    width:100%;
    border-collapse: collapse;
}
.select-multi-column-options:hover{
    background-color:#f2f2f2;
    color:#000;
    cursor: pointer;
}
.select-multi-column-options td{
    padding:4px 10px;
    color:#333;
    font-size: 13px;
    border-bottom:1px solid #d9d9d9;
}