body {
  margin: 0;
  padding: 0;
  font-family: arial, sans-serif;
  font-size: 16px;
}

table {
  width: 100%;
}

.center-loader {
  text-align: center;
  margin-top: 10;
}

.left-result {
  text-align: left;
  margin-top: 10;
}
